import React from 'react';

function App() {
  return (
  <div className="flex h-screen w-full justify-center items-center">
    <img alt="Нечего тут делать" src="/no-way.png" />
  </div>
  );
}

export default App;
